import ListaEmpresas from './empresas/ListaEmpresas'
import ListaEmpleados from './Empleados/ListaEmpleados'
import sesionwhatsapp from './../whatsapp/sesionwhatsapp'


const MantenimientoRoutes = [
  {
    path: `/template/empresas`,
    component: ListaEmpresas,
  },
  {
    path: `/template/empleado`,
    component: ListaEmpleados,
  },
  {
    path: `/template/whatsapp`,
    component: sesionwhatsapp,
  },
]

export default MantenimientoRoutes
