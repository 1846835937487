import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Skeleton, Button } from "antd";
import s from "./Componente.module.scss";
import Dot from "../../../components/Dot/Dot";
import { BURNFALTANTE } from "../../../conf/mutation";
import { BURNLISTFALTANTES } from "../../../conf/mutation";
import { gql, useQuery, useMutation } from "@apollo/client";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { LoadScript } from "@react-google-maps/api";
import { DownloadOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import { Workbook } from "exceljs";


const UsuariosPendientesburn = ({ idEmpresa ,encno}) => {
  const [encTotal] = useMutation(BURNFALTANTE);
  const [encFaltantes] = useMutation(BURNLISTFALTANTES);

  const [load, setLoad] = useState(true);
  const [dataTotalResp, setdataTotalResp] = useState([]);
  const [dataFaltantes, setdataFaltantes] = useState([]);


  const getTotalEnc = async () => {
    try {
      const { data } = await encTotal({
        variables: {
          input: { EMP: String(idEmpresa), ENC: String(encno)},
        },
      });

      let dataJson = JSON.parse(data.burnOutFaltante.mensaje);
      let obj = {
        name: `Finalizados: ${dataJson.respuesta[0].f1}`,
        value: parseInt(dataJson.respuesta[0].f1),
        color: "#FF8F00",
      };
      let obj2 = {
        name: `Pendientes: ${dataJson.respuesta[0].f2}`,
        value: parseInt(dataJson.respuesta[0].f2),
        color: "#C7D0D9",
      };
      setdataTotalResp([obj, obj2]);
      setLoad(false);
    } catch (ex) {
      //console.log(ex)
    }
  };

  const getFaltantes = async (e) => {
    try {
      const { data } = await encFaltantes({
        variables: {
          input: { EMP: String(idEmpresa), ENC: String(encno) },
        },
      });

      let dataJson = JSON.parse(JSON.stringify(data.burnOutListFatante.mensaje));
      let dataNew= JSON.parse(dataJson);
  
       let nuevoJson = {
         respuesta: dataNew.respuesta.map(item => ({
             Codigo: item.f0,
             Email: item.f1,
             Nombre: item.f2,
             Codencargado: item.f3,
             Nomencargado: item.f4,
             Resultado: item.f5
         }))
         };

        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Resultado");
        worksheet.columns = [
          { header: "CODIGO EMPLEADO", key: "Codigo", width:25 },
          { header: "EMAIL", key: "Email", width: 40 },
          { header: "NOMBRE", key: "Nombre", width: 50},
          { header: "CODIGO ENCARGADO", key: "Codencargado", width: 25 },
          { header: "NOMBRE ENCARGADO", key: "Nomencargado", width: 50 },
          { header: "RESULTADO", key: "Resultado", width: 40 }
        ];
      
        nuevoJson.respuesta.map((item, index2) => worksheet.addRow(item));
        workbook.xlsx.writeBuffer().then(function (data) {
          var blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, "reporte-avances.xlsx");
        });
      
    } catch (ex) {
      console.log(ex);
    }





  }




  useEffect(() => {
    getTotalEnc();
   
  }, []);
  return (
    <div style={{ height: "316px" }}>
      {load ? (
        <Skeleton className="mt-5"></Skeleton>
      ) : (
        <>
          <ResponsiveContainer width="100%" height={200}>
            <PieChart>
              <Pie
                data={dataTotalResp}
                innerRadius={50}
                outerRadius={80}
                dataKey="value"
              >
                {dataTotalResp.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <div className={s.donutLabels}>
            {dataTotalResp.map((entry, index) => (
              <div key={uuidv4()} className={s.label}>
                <Dot color={entry.color} />
                <span className="body-3 ml-2">{entry.name}</span>
              </div>
            ))}
            <br />
            <Button type="primary" onClick={ getFaltantes} icon={<DownloadOutlined />} size={"large"}>
              Exportar en Excel
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default UsuariosPendientesburn;
