import Empadmin from './Empadmin';
import Reporte from './reportes/Reporte';
import ReportePlanAccion from './reportes/ReportePlanAccion';
import BornOut from './BornOut';
import whatsapp from "../whatsapp/sesionwhatsapp";
const EmpAdminRoutes = [
    {
        path: `/template/dashboardAdmin`,
        component: Empadmin
    },
    {
        path: `/template/reporte`,
        component: Reporte
    },
    {
        path: `/template/reportePlanAccion`,
        component: ReportePlanAccion
    },
    {
        path: `/template/BornOut`,
        component: BornOut
    },
    {
        path: `/template/whatsapp`,
        component: whatsapp
    },
]

export default EmpAdminRoutes