import React, {  useEffect } from "react";
import "./SesionWhatsapp.css"; // Don't forget to create this CSS file


const SesionWhatsapp = () => {
  const [qrCode, setQrCode] = React.useState(null);

  //useEffect(() => {
    // Función para actualizar el QR sin modificar la URL base
  //  const updateQRCode = () => {
 //     setQrCode(`http://localhost:3008/` + `#${new Date().getTime()}`); 
 //   };

 //   updateQRCode(); // Llamar la primera vez

    

    
 // }, []);


  return (
    <div className="whatsapp-container">
      <div className="login-card">
        {/* Left content */}
        <div className="login-content">
          <h1 className="login-title">Inicia sesión en WhatsApp para Empowerment</h1>
          <p className="login-description">
            Envía mensajes para realizar encuetas a personas sin correo
            electronico
          </p>

          <ol className="login-steps">
            <li>
              <span className="step-number">1.</span>
              <span className="step-text">Abre WhatsApp en tu teléfono.</span>
            </li>

            <li>
              <span className="step-number">2.</span>
              <span className="step-text">
                Toca Menú <span className="icon-menu">⋮</span> en Android o
                Ajustes <span className="icon-settings">⚙</span> en iPhone.
              </span>
            </li>

            <li>
              <span className="step-number">3.</span>
              <span className="step-text">
                Toca Dispositivos vinculados y, luego, Vincular un dispositivo.
              </span>
            </li>

            <li>
              <span className="step-number">4.</span>
              <span className="step-text">
                Apunta tu teléfono hacia esta pantalla para escanear el código
                QR.
              </span>
            </li>
          </ol>

          
        </div>

        {/* Right content - QR code */}
        <div className="qr-code-container">
          <div className="qr-code">
            {/* This would be an actual QR code image in production */}
            <img className="qr-code" src={qrCode} alt="QR code" height={250} />
          </div>

          <div className="session-checkbox">
            <input type="checkbox" id="keep-session" checked />
            <label htmlFor="keep-session">
              Mantener la sesión iniciada en este navegador
              <span className="info-icon">ⓘ</span>
            </label>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="encryption-notice">
        <span className="lock-icon">🔒</span>
        <span>Los mensajes que se envian están cifrados de extremo a extremo</span>
      </div>
    </div>
  );
};

export default SesionWhatsapp;
