import React from "react";
import { Badge } from "antd";
const Quitting = () => {
  return (
    <Badge.Ribbon text="Participante Pasivo" color="green">
      <div style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
        {/* Sección: Quiet Quitting */}
        <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Participante Pasivo
        </h3>
        <ul style={{ listStyleType: "none", paddingLeft: "0", marginTop: "0" }}>
          <li style={{ marginBottom: "10px" }}>
            - Este perfil corresponde a un colaborador cuyo desempeño puede
            describirse como promedio. No sobresale y tiende a adherirse
            estrictamente a los horarios laborales. Tiende a no hacer esfuerzos
            adicionales por la organización o por su trabajo. Esta condición
            inicia por algún evento relacionado a sus responsabilidades
            laborales que luego los lleva a un cansancio emocional por tener que
            continuar desempeñando estas responsabilidades.
          </li>
          <li style={{ marginBottom: "10px" }}>
            - En el contexto de esta prueba, un colaborador con sentimientos de
            “Participante Pasivo” cuenta con:
            <ul
              style={{
                listStyleType: "circle",
                paddingLeft: "40px",
                marginTop: "5px",
              }}
            >
              <li>Nivel alto de cansancio emocional</li>
              <li>Nivel bajo de realización personal</li>
            </ul>
          </li>
        </ul>

        {/* Espacio entre secciones */}
        <div style={{ marginTop: "20px" }}></div>

        {/* Sección: Mejores prácticas */}
        <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Mejores prácticas
        </h3>
        <ul style={{ listStyleType: "none", paddingLeft: "0", marginTop: "0" }}>
          <li style={{ marginBottom: "10px" }}>
            - Realizar una revisión de las funciones del colaborador para
            entregar autonomía, crecimiento y la posibilidad de toma de
            decisiones.
          </li>
          <li style={{ marginBottom: "10px" }}>
            - Evitar presionar al colaborador a que “haga más”, esto tendrá el
            efecto opuesto en el esfuerzo del colaborador.
          </li>
          <li>
            - Crear espacios u oportunidades para que el colaborador pueda
            trabajar en proyectos que le interesen y que no son,
            específicamente, parte de su responsabilidad.
          </li>
        </ul>
      </div>
    </Badge.Ribbon>
  );
};

export default Quitting;
