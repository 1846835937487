import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import{
  Skeleton,
  } from 'antd';
import s from "./Componente.module.scss";
import Dot from "../../../components/Dot/Dot";
import { ENCCHATTOTEmp } from '../../../conf/mutation';
import { gql, useQuery, useMutation } from '@apollo/client';
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { LoadScript } from "@react-google-maps/api";


const UsuariosPendientes = ({idEmpresa}) => {
  const [encTotal] = useMutation(ENCCHATTOTEmp)
  const [load, setLoad] = useState(true)
  const [dataTotalResp, setdataTotalResp] = useState([]);

  const getTotalEnc = async () => {
   //let id = localStorage.getItem('emp')
   try{
 
      const {data} = await encTotal(
        {
          variables:{
            input: { ID: String(idEmpresa)}
          }
        }
      )
      console.log('usuResp: ',JSON.parse(data.encChatTotG.mensaje))
      let dataJson = JSON.parse(data.encChatTotG.mensaje)
      let obj = { name: `Finalizados: ${dataJson.totResp}`, value: dataJson.totResp, color: '#FF8F00' }
      let obj2 =  { name: `Pendientes: ${dataJson.totEmp}`, value: dataJson.totEmp, color: '#C7D0D9' }
      setdataTotalResp([obj, obj2])
      setLoad(false)
   }catch(ex){
    console.log(ex)
   }
    
  }
  useEffect(() => {
    getTotalEnc()
  }, [])
  return (

    <div style={{ height: "316px" }}>
      {
        (load )?
        ( 
          <Skeleton className="mt-5"></Skeleton>
        ):
        (
          <>
            <ResponsiveContainer width="100%" height={200}>
              <PieChart >
                <Pie
                  data={dataTotalResp}
                  innerRadius={50}
                  outerRadius={80}
                  dataKey="value"
                >
                  {dataTotalResp.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <div className={s.donutLabels}>
              {dataTotalResp.map((entry, index) => (
                <div key={uuidv4()} className={s.label}>
                  <Dot color={entry.color} />
                  <span className="body-3 ml-2">{entry.name}</span>
                </div>
              ))}
            </div>
          </>
        )
        }
    </div>
  )
};

export default UsuariosPendientes;
